import React from "react";

import Slider from "react-slick";
import UeSvg from "../assets/img/ueSvg";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

const DotationUE = () => {
  const lang = "pl";

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layout
      seoTitle="Dotacje UE"
      lang={lang}
      translationEN="/en/eu-grants/"
      translationDE="/de/eu-zuschusse/"
    >
      <SmallBanner title="Dotacje UE" lang={lang} />
      <section className="dotation-ue-info">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">POIR Działanie 1.1.1</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <UeSvg viewBox="46 0 670.6 181.4" />
              <p className="mb-30">
                Palettenwerk Kozik Sp. z o.o. realizuje projekt dofinansowany
                z Funduszy Europejskich „Opracowanie innowacyjnej technologii
                budownictwa modułowego, prefabrykowanego na bazie drewna przez
                Palettenwerk Kozik Sp. z o.o.”.
              </p>
              <p className="mb-30">
                Celem projektu jest opracowanie i wdrożenie technologii
                budownictwa modułowego, prefabrykowanego na bazie drewna, a
                dzięki temu znaczące poprawienie poziomu innowacyjności, poprzez
                wykorzystanie rezultatów prac B+R.
              </p>
              <p>Dofinansowanie projektu z UE: 9 113 867,55 zł</p>
            </div>
          </div>
        </div>
      </section>
      <section className="dotation-ue-info dotation-ue-info--second">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">RPOWŚ Działanie 2.5</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <img
                className="mb-60"
                src={require("../assets/img/dotation-ue2.png")}
                alt="Paletten"
              />
              <p className="mb-30">
                Palko Spółka z ograniczoną odpowiedzialnością realizuje projekt
                dofinansowany z Funduszy Europejskich „Zakup innowacyjnych
                maszyn i urządzeń na potrzeby produkcji w firmie Palko”.
              </p>
              <p className="mb-30">
                Projekt zakłada wprowadzenie zmian produktowych,
                technologicznych, organizacyjnych oraz marketingowych o
                charakterze innowacyjnym. Wyszczególnione zmiany spowoduje zakup
                zaawansowanych nowych technologii oraz wdrożenie wyników prac
                B+R.
              </p>
              <p>Dofinansowanie projektu z UE: 888 505,25 zł</p>
            </div>
          </div>
        </div>
      </section>
      <section className="dotation-ue-info dotation-ue-info--second">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">P.O.I.R <br />2014-2020</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
            <img
                className="mb-60"
                src={require("../assets/img/ue_logo_3.jpg")}
                alt="Paletten"
              />
              <p className="mb-30">
              Program Operacyjny Inteligentny Rozwój 2014-2020
              </p>
              <p className="mb-30">
              Poddziałanie 3.2.1 Badania na rynek
              </p>
              <p className="mb-30">
              Projekt nr POIR.03.02.01-26-0002/18
              </p>
              <p className="mb-30">
              Tytuł projektu: Opakowanie drewniane „Paleta 4.0” dedykowane dla środowiska Industry 4.0
              </p>
              <p className="mb-30">
              Cel projektu: Wdrożenie wyników prac B+R poprzez zaoferowanie na rynku innowacyjnego opakowania drewnianego dedykowanego dla przemysłu 4.0 w oparciu o innowacyjne procesy produkcyjno-logistyczne związane z produkcją produktu. Dzięki realizacji projektu nastąpi wzrostu innowacyjności i konkurencyjności Palko Sp. z o.o. poprzez wprowadzenie na rynek innowacji produktowej w postaci palety wpisującej się w standardy czwartej rewolucji przemysłowej: możliwość komunikacji i identyfikacji, większe bezpieczeństwo, możliwość pomiaru parametrów towaru, stabilność palety, zaawansowana technicznie konstrukcja, duża skalowalność i wiele możliwych wariantów konfiguracji.
              </p>
              <p className="mb-30">Beneficjent: Palko Sp. z o.o.</p>
              <p className="mb-30">
              Wartość projektu ogółem: 19 134 834,04 zł
              </p>
              <p className="mb-30">
              Wartość wydatków kwalifikowanych: 17 319 400,00 zł
              </p>
              <p className="mb-30">
              Dofinansowanie projektu z UE: 7 773 730,00 zł
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="timeline timeline--small">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-20">Realizacje</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider {...settings} className="timeline__slider">
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../assets/img/realizations1.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../assets/img/realizations2.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../assets/img/realizations3.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../assets/img/realizations4.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../assets/img/realizations1.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../assets/img/realizations2.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../assets/img/realizations3.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../assets/img/realizations4.jpg")})`,
                    }}
                  ></div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DotationUE;
